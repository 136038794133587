
import EventTeaser from '@/components/teasers/EventTeaser'
export default {
  components: {
    EventTeaser,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  computed: {
    filteredEvents() {
      return this.data.event_categories &&
        this.data.event_categories.data.length > 0
        ? this.$store.state.events.filter((event) => {
            return this.data.event_categories.data.some((f) => {
              return event.attributes.event_category &&
                event.attributes.event_category.data
                ? Number(f.id) ===
                    Number(event.attributes.event_category.data.id)
                : false
            })
          })
        : this.$store.state.events
    },
    events() {
      let events =
        this.data.events.data && this.data.events.data.length > 0
          ? this.filteredEvents.filter((event) => {
              return this.data.events.data.some((f) => {
                return f.id === event.id
              })
            })
          : this.filteredEvents

      const today = new Date()
      if (this.data.previous) {
        events = events.filter((event) => {
          const date = new Date(event.attributes.date)
          return date < today
        })
      } else {
        events = events.filter((event) => {
          const date = new Date(event.attributes.date)
          return date >= today
        })
      }
      const limit = this.data.limit || 3
      if (limit !== -1) {
        events = events.slice(0, limit)
      }
      if (!this.data.previous) {
        events = events.reverse()
      }

      return events
    },
  },
}
